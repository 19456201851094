html,
body {
  font-size: 15px;
}

// header & menu
.header-responsive {
  display: none;
  @media only screen and (max-width: 1023px) {
    display: inline;
  }
}

.login-bg {
  background: url(../img/login-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.dashboard-bg {
  background: url(../img/dashboard-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.mt-48px {
  margin-top: 48px;
}

.brand-logo {
  display: flex;
  img {
    width: auto;
    height: 32px;
    margin-right: 10px;
  }
}

.h-50 {
  height: 50.5px;
}

// button

button:focus {
  outline: 0;
}

.lightblue-btn {
  background-color: rgb(191, 219, 254) !important;
  color: rgb(37, 99, 235) !important;
}

.btn-dropdown {
  width: 20px;
  height: 20px;
  padding: 0;
}

// spinner

.animate-spin {
  animation: spin 2.5s linear infinite;
  fill: rgb(191, 219, 254);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whitespace-nowrap {
  white-space: nowrap !important;
}
